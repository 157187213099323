const data = {
    turkmen:{
        HomePage:' Baş sahypa',
        Logo:"../assets/logoTM.png",
        Services: {
            title:'Hyzmatlar',
            1: 'Taslama işleri',
            2: 'Gurluşyk-gurnama işleri',
            3: 'Metaldan gurnamalary öndürmek ',
            4: 'Bug gazanlarynyň desgalary we olara hyzmat etmek',
            5: 'Mahabat önümçiligi'
        },
        OurWorks: 'Biziň işlerimiz',
        AboutUs: {
            title: 'Biz barada',
            1: '“Paýhasly Ýoldaş” hususy kärhanasynyň hünärmenleri binalaryň we desgalaryň indiwidual taslamalaryny hödürleýärler. Biz müşderiniň maksatlaryny we talaplaryny doly göz öňünde tutup, “noldan başlap” gaýtalanmajak teklip düzüp bileris. Siz toplumlaýyn çemeleşmä, innowasiýalaryň ulanylmagyna, dizaýnda döwrebap netijelere bil baglap bilersiňiz ',
            2: "Biz hemişe islendik senagat desgasyny, logistika merkezini ýa-da oba hojalyk toplumyny, söwda, lukmançylyk, sport ýa-da bilim edarasyny gurmaga, täzeden gurmaga, durkuny täzelemäge, tehniki täzeden enjamlary gurmaga taýýardyrys",
            3: "“Paýhasly Ýoldaş” hususy kärhanasy islendik maksat üçin dürli çylşyrymly metal gurnamalary öndürmekde ýöriteleşýär. Kärhanada uly göwrümli çyzgylar boýunça metal gurnamalary öndürmek üçin ýörite enjamlar we ökde hünärli işgärler bar.",
            4: 'Şertnamanyň çäginde “Paýhasly Ýoldaş” hususy kärhanasy bug gazan jaýlarynyň esasy elementleriniň işleýşini saklamak üçin her aýda işleriň giň görnüşlerini ýerine ýetirýär. Olar öz içine: gaz we ýylylyk çalşygy enjamlaryny, ölçeýji we dolandyryjy abzallary, himiki suw arassalaýyş ulgamlaryny we ş.m. alýar. - Within the framework of the contract, the Individual Enterprise "Payhasly Yoldash"',
            5: 'Biziň kompaniýamyz mahabat bazarynda on ýyldan gowrak wagt bäri işleýär. Bizde döwrebap, ýokary hilli sergi enjamlaryny öndürmek üçin uly önümçilik bazamyz bar. Biziň kompaniýamyzda Siziň gaýtalanmajak keşbiňizi döretmäge we mahabat eskizlerini taýýarlamaga hemişe kömek etjek tejribeli mahabat dizaýnerlerini işleýär. Islendik çylşyrymly taslamany mümkin boldugyça gysga wagtda durmuşa geçirmäge taýýardyrys'
        },
        Contacts: {
            title: 'Habarlaşmak üçin',
            address: 'SALGYMYZ',
            street: 'Galkynyş köç., 66',
            city: 'Aşgabat ş., Türkmenistan',
            phone: 'TELEFON',
            email: 'Elektron poçta ',
            sendb: 'Hyzmatdaşlyk üçin hat ugratmak',
            name: 'Ady',
            company: 'Kompaniýa',
            subjMess:'Hatyň ady ',
            send: 'Ibermek'
        },
        Main1: 'Sizi ýatda ',
        Main2: ' galar ýaly edýäris ',
        OurPartners: 'Biziň hyzmatdaşlarymyz',
    },
    russian:{
        HomePage: 'Главная',
        Logo:"../assets/logoRU.png",
        Services:{
            title:'Услуги',
            1: 'Проектирование',
            2: 'Строительно-монтажные работы',
            3: 'Производство металлоконструкций ',
            4: 'Котельные установки и обслуживание',
            5: 'Рекламное производство'

        },
        OurWorks: 'Кейсы ',
        AboutUs: {
            title: 'О нас',
            1:'Специалисты «Пайхаслы йолдаш» предлагают индивидуальное проектирование зданий и сооружений. Мы можем разработать уникальное предложение «с нуля», полностью учитывающее цели и требования клиента. Вы можете рассчитывать на комплексный подход, использование инноваций, современные идеи в дизайне',
            2:'Мы всегда готовы построить любой промышленный объект, логистический центр или агрокомплекс, торговое, медицинское, спортивное или образовательное учреждение, провести перепланировку, реконструкцию, техническое переоснащение',
            3:'Индивидуальное Предприятие «Пайхаслы йолдаш» специализируется на изготовлении металлоконструкций разной сложности, для любых целей использования. Предприятие оснащено специальной техникой и квалифицированными кадрами для изготовления металлоконструкций по чертежам большого объема и назначения',
            4:'В рамках договора Индивидуальное Предприятие «Пайхаслы йолдаш» на ежемесячной основе выполняет широкий перечень работ для поддержания работоспособности основных элементов котельных. Он включает техническое обслуживание газового и теплообменного оборудования, КИП, систем химической водоподготовки и т.д.',
            5:'Наша компания уже более десяти лет работает на рекламном рынке. Мы имеем большую производственную базу для изготовления современного, качественного выставочного оборудования. В штате нашей компании опытные дизайнеры по рекламе, которые всегда помогут создать ваш неповторимый образ и изготовят эскизы рекламы. Мы готовы реализовать проекты любой сложности в самые короткие сроки.'
        },
        Contacts: {
            title:'Контакты',
            address: 'АДРЕС',
            street: 'ул. Галкыныш, 66 ',
            city: 'г. Ашхабад, Туркменистан, ',
            phone: 'ТЕЛЕФОН ',
            email: 'ЭЛЕКТРОННАЯ ПОЧТА',
            sendb: 'ОТПРАВИТЬ БРИФ ',
            name: 'Имя',
            company: 'Компания',
            subjMess:'Тема сообщения ',
            send: 'ОТПРАВИТЬ'
        },
        Main1: 'Делаем так,',
        Main2: 'чтобы вас запомнили',
        OurPartners: 'НАШИ ПАРТНЕРЫ'
    },
    english:{
        HomePage:'Home',
        Logo:"../assets/logoEN.png",
        Services: {
            title:'Services',
            1: 'Designing',
            2: 'Construction and installation works',
            3: 'Production of metal structures',
            4: 'Boiler installations and maintenance',
            5: 'Advertising production'
        },
        OurWorks: 'Our works',
        AboutUs: {
            title: 'About us',
            1: 'Specialists of the “Payhasly yoldash” offer individual design of buildings and structures. We can develop a unique offer "from scratch", fully taking into account the goals and requirements of the client. You can count on an integrated approach, the use of innovations, modern ideas in design',
            2: "We are always ready to build any industrial facility, logistics center or agricultural complex, commercial, medical, sports or educational institution, to carry out redevelopment, reconstruction, technical re-equipment",
            3: 'The individual Enterprise "Payhasly yoldash" specializes in the manufacture of metal structures of varying complexity, for any purpose of use. The company is equipped with special equipment and qualified personnel for the manufacture of metal structures according to drawings of large volume and purpose.',
            4: 'Within the framework of the contract, the Individual Enterprise "Payhasly Yoldash" performs a wide range of works on a monthly basis to maintain the operability of the main elements of boiler houses. It includes maintenance of gas and heat exchange equipment, instrumentation, chemical water treatment systems, etc.',
            5: 'Our company has been working in the advertising market for more than ten years. We have a large production base for the manufacture of modern, high-quality exhibition equipment. Our company employs experienced advertising designers who will always help you create your unique image and make sketches of advertising. We are ready to implement projects of any complexity in the shortest possible time',
        },
        Contacts: {
            title: 'Contacts',
            address: 'ADDRESS',
            street: 'GGalkynysh str., 66',
            city: 'Ashgabat c., Turkmenistan',
            phone: 'TELEPHONE',
            email: 'E-mail',
            sendb: 'SEND A BRIEF',
            name: 'Name',
            company: 'Company',
            subjMess:'Subject of the message',
            send: 'Send'
        },
        Main1: 'We will make ',
        Main2: ' you unforgettable',
        OurPartners: 'Our partners',
    },
}

export default data