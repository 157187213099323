import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Carousel from 'nuka-carousel'
import { useMediaQuery } from 'react-responsive'
import fourthProject from '../assets/images/image31.jpg'
import fifthProject from '../assets/images/image32.jpg'
import sixthProject from '../assets/images/image33.jpg'
import abrLogo from '../assets/logos_upd/abris.png'
import bereketliLogo from '../assets/logos_upd/bereketli.png'
import bouyLogo from '../assets/logos_upd/boybod.png'
import akyolLogo from '../assets/logos_upd/akyol.png'
import aretLogo from '../assets/logos_upd/areti.png'
import calikLogo from '../assets/logos_upd/calik.png'
import goshaLogo from '../assets/logos_upd/gosa.png'
import philipsLogo from '../assets/logos_upd/philips.png'
import hoshzamanLogo from '../assets/logos_upd/hoszaman.png'
import huaweiLogo from '../assets/logos_upd/huawei.png'
import mannLogo from '../assets/logos_upd/mannol.png'
import mothercareLogo from '../assets/logos_upd/mother.png'
import samsungLogo from '../assets/logos_upd/samsung.png'
import sumitomoLogo from '../assets/logos_upd/sumitomo.png'
import tatLogo from '../assets/logos_upd/tatneft.png'
import torkuLogo from '../assets/logos_upd/torku.png'
import totalLogo from '../assets/logos_upd/total.png'
import turkmenTranzitLogo from '../assets/logos_upd/tt.png'
import unicefLogo from '../assets/logos_upd/unicef.png'
import whoLogo from '../assets/logos_upd/who.png'
import ynamdarLogo from '../assets/logos_upd/ynamdar.png'
import buigLogo from '../assets/logos_upd/buig.png'
import demirLogo from '../assets/logos_upd/demir.png'
import hasarLogo from '../assets/logos_upd/hasar.png'
import hbLogo from '../assets/logos_upd/hb.png'
import madoLogo from '../assets/logos_upd/mado.png'
import novadaLogo from '../assets/logos_upd/novada.png'
import strahLogo from '../assets/logos_upd/strah.png'
import unfpaLogo from '../assets/logos_upd/unfpa.png'
import yowsanLogo from '../assets/logos_upd/yowsan.png'
import data from '../data'
import { MenuContext } from '../context/navState'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 9rem;
  padding-right: 9rem;

  @media (max-width: 1430px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 25px;
  }
`
const SectionCaption = styled.div`
  margin-top: 5rem;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: inline;
    flex-direction: column;
    align-items: flex-start;
  }
`
const Container = styled.div`
  margin: 0 0 5rem 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  padding: 0;

  @media (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`
const LogoSlider = styled.div`
  margin: 4rem 0 4rem 4rem;
  position: relative;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin: 1.7rem 0 1.7rem 1.7rem;
  }
`

const Caption = styled.h2`
  color: #08a332;
  font-family: 'FuturaPT-Bold', cursive;
  margin-right: 10rem;
  font-size: 65px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    margin-right: 8rem;
  }
`

const MenuLink = styled.a`
  border-bottom: 2px solid transparent;
  color: #05ba94;
  margin: 15px 25px 0 0;
  font-size: 1.6rem;
  font-weight: 300;
  text-decoration: none;
  font-family: 'FuturaPT', sans-serif;
  letter-spacing: 0.6px;

  &:focus {
    border-bottom-color: #fa0305;
    color: #2fba93;
  }

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`

const Image = styled.img`
  width: 33rem;
  height: 23rem;

  @media (max-width: 1850px) {
    width: 32rem;
  }

  @media (max-width: 1800px) {
    width: 29rem;
  }

  @media (max-width: 1600px) {
    width: 26rem;
  }

  @media (max-width: 1450px) {
    width: 24rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0.2rem;
  }
`
const Logo = styled.img`
  width: 8.5rem;

  @media (max-width: 1450px) {
    width: 7.5rem;
  }

  @media (max-width: 768px) {
    width: 5.2rem;
  }
`

const allCases = [
  {
    image: fourthProject,
    type: 1,
  },
  {
    image: fifthProject,
    type: 2,
  },
  {
    image: sixthProject,
    type: 3,
  },
]

function Cases() {
  const [caseCategories, setCaseCategories] = useState([
    {
      id: 1,
      title: 'Все проекты',
    },
    {
      id: 2,
      title: 'Creative',
    },
    {
      id: 3,
      title: 'Digital',
    },
  ])
  const [activeCases, setActiveCases] = useState(allCases)
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
  const {datas} = useContext(MenuContext)

  const clickCategory = (id) => {
    if (id === 1) {
      setActiveCases(allCases)
    } else {
      const filteredCases = allCases.filter((item) => item.type === id)
      setActiveCases(filteredCases)
    }
  }

  return (
    <Main id="cases">
      {/* <SectionCaption>
        <Caption>Кейсы</Caption>
        {caseCategories.map((category) => (
          <MenuLink
            // eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            onClick={() => clickCategory(category.id)}
          >
            {category.title}
          </MenuLink>
        ))}
      </SectionCaption> */}
      {/* <Container>
        {activeCases.map((item) => (
          <Image src={item.image} alt="All Projects" />
        ))}
      </Container> */}
      <Caption>{datas['OurPartners']}</Caption>
      <LogoSlider>
        <Carousel
          style={{ position: 'absolute' }}
          wrapAround
          withoutControls
          autoplay
          slidesToShow={isMobile === true ? 3 : 7}
        >
          <Logo src={abrLogo} alt="abr" />
          <Logo src={bereketliLogo} alt="Bereketli" />
          <Logo src={bouyLogo} alt="Bouygues" />
          <Logo src={akyolLogo} alt="Akyol" />
          <Logo src={aretLogo} alt="Aret" />
          <Logo src={calikLogo} alt="Calik enerji" />
          <Logo src={goshaLogo} alt="Gosha Chynar" />
          <Logo src={hoshzamanLogo} alt="Hoshzaman" />
          <Logo src={huaweiLogo} alt="Huawei" />
          <Logo src={mannLogo} alt="Mann" />
          <Logo src={mothercareLogo} alt="Mothercare" />
          <Logo src={philipsLogo} alt="Philips" />
          <Logo src={samsungLogo} alt="Samsung" />
          <Logo src={sumitomoLogo} alt="Sumitomo" />
          <Logo src={tatLogo} alt="Tatnet" />
          <Logo src={torkuLogo} alt="Torku" />
          <Logo src={totalLogo} alt="Total" />
          <Logo src={turkmenTranzitLogo} alt="turkmenTranzit" />
          <Logo src={unicefLogo} alt="Unicef" />
          <Logo src={whoLogo} alt="WHO" />
          <Logo src={ynamdarLogo} alt="ynamdar" />
          <Logo src={buigLogo} alt="buig" />
          <Logo src={demirLogo} alt="demir" />
          <Logo src={hasarLogo} alt="hasar" />
          <Logo src={hbLogo} alt="halk bank" />
          <Logo src={madoLogo} alt="mado" />
          <Logo src={novadaLogo} alt="novada" />
          <Logo src={strahLogo} alt="strah" />
          <Logo src={unfpaLogo} alt="unfpa" />
          <Logo src={yowsanLogo} alt="yowsan" />
        </Carousel>
      </LogoSlider>
    </Main>
  )
}

export default Cases
