import { useContext, useState } from 'react'
import styled from 'styled-components'
import { MenuContext } from '../context/navState'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 9rem;
  padding-right: 9rem;


  @media (max-width: 1430px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 25px;
  }
`
const ContactsContainer = styled.div`

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Caption = styled.h2`
  color: #08a332;
  font-family: 'FuturaPT-Bold', cursive;
  margin-right: 10rem;
  font-size: 65px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    margin-right: 8rem;
  }
`

const Info = styled.div`
  padding-left: 3rem;
  padding-right: 8rem;

  @media (max-width: 768px) {
    padding: 0 0 30px;
  }
`
const SendInfo = styled.div`
  flex-grow: 1;

  @media (max-width: 768px) {
  flex-grow: 0;
  }
`
const Form = styled.form`
  display: flex;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 12px;
  }
`
const FormBox = styled.div`
  display: block;
  flex-grow: 1;
  padding-right: 3rem;
`

const Label = styled.label`
  display: block;
  color: #7e8889;
  font-size: 19px;
`
const Input = styled.input`
  display: block;
  appearance: none;
  width: 100%;
  background: 0 0;
  border: 1px solid transparent;
  border-bottom-color: #262e36;
  color: #fff;
  padding-top: 15px;
  font-size: 16px;
  font-weight: 300;
}

  :focus {
    outline: none;
  }
`
const Title = styled.h4`
  color: #c8d2d3;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 15px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`
const Text = styled.p`
  color: #7e8889;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Button = styled.button`
  margin: 5rem;
  border: 2px solid #c8d2d3;
  transition: all 0.15s ease-out;
  padding: 15px 20px;
  font-family: 'FuturaPT-Bold', sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: #c8d2d3;
  background-color: #101820;
  float: right;

  :hover {
    background-color: #2fba93;
    border: 2px solid #2fba93;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    margin: 2rem 0 3rem 0;
    font-size: 16px;
  }
`

function Contacts() {
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const {datas}=useContext(MenuContext)
  const submitValue = () => {
    const details = {
      'First Name': firstName,
      'Email': email,
      'Company': company,
      'Message': message,
    }
  }

  return (
    <Main id="contacts">
      <Caption>{datas['Contacts']['title']}</Caption>
      <ContactsContainer>
        <Info>
          <Title>{datas['Contacts']['address']}</Title>
          <Text>
          {datas['Contacts']['street']} <br /> {datas['Contacts']['city']}, 744000
          </Text>
          <Title>{datas['Contacts']['phone']}</Title>
          <Text>+993 (65) 57-44-99 <br /> +993 (12) 22-43-03</Text>
          <Title>{datas['Contacts']['email']}</Title>
          <Text>payhasly.yoldash@gmail.com <br /> payhasly.marketing@gmail.com</Text>
        </Info>
        <SendInfo>
          <Title>{datas['Contacts']['sendb']}</Title>
          <Form>
            <FormBox>
              <Label>{datas['Contacts']['name']}</Label>
              <Input
                type="text"
                required="required"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormBox>
            <FormBox>
              <Label>E-mail</Label>
              <Input
                type="email"
                required="required"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormBox>
          </Form>
          <Form>
            <FormBox>
              <Label>{datas['Contacts']['company']}</Label>
              <Input
                type="text"
                required="required"
                onChange={(e) => setCompany(e.target.value)}
              />
            </FormBox>
            <FormBox>
              <Label>{datas['Contacts']['subjMess']}</Label>
              <Input
                type="text"
                required="required"
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormBox>
          </Form>
          <ButtonContainer>
          <Button onClick={submitValue}>{datas['Contacts']['send']}</Button>
          </ButtonContainer>
        </SendInfo>
      </ContactsContainer>
    </Main>
  )
}

export default Contacts
