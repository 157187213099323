import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import firstBlock from '../assets/images/1.jpg'
import secondBlock from '../assets/images/2.jpg'
import thirdBlock from '../assets/images/3.jpg'
import fourth from '../assets/images/architect.jpg'
import fifth from '../assets/images/stend.jpg'
import { useContext } from 'react'
import { MenuContext } from '../context/navState'

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding-left: 9rem;
  padding-right: 9rem;

  @media (max-width: 1430px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 25px;
  }
`

const Caption = styled.h2`
  display: flex;
  margin-top: 5rem;
  font-family: 'FuturaPT-Bold', cursive;
  font-size: 65px;
  line-height: 65px;
  color: #08a332;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    margin-right: 8rem;
  }
`
const ItemCaption = styled.h3`
  margin-top: 15px;
  padding: 0 40px;
  font-family: 'FuturaPT-Book';
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 0 20px;
    margin-top: 0;
  }
`

const Container = styled.div`
  margin-bottom: 0;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: block;
  }
`
const WhiteBox = styled.div`
  background: #fff;
  color: #222828;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 3;
`
const TitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  background: linear-gradient(to bottom, rgba(77, 4, 5, 0) 0, #4d0405 100%);
`

const Title = styled.h2`
  padding: 0 40px;
  position: relative;
  font-family: 'FuturaPT-Bold', cursive;
  font-size: 26px;
  font-weight: 900;
  line-height: 32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0 20px;
    margin: 0;
  }
`
const ImageContainer = styled.div`
  position: relative;
`

const Image = styled.img`
  display: block;
  width: 50%;
  height: 29rem;

  @media (max-width: 1450px) {
    height: 24rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 20rem;
    object-fit: cover;
  }
`

function AboutUs() {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })

  return isMobile ? <AboutUsMobile /> : <AboutUsDesktop />
}

function AboutUsDesktop() {
  const {datas} = useContext(MenuContext)
  return (
    <Main id="about-us">
      <Caption>{datas['AboutUs']['title']}</Caption>
      <Container>
        <WhiteBox>
          <Title>{datas['Services']['1']}</Title>
          <ItemCaption>
            {datas['AboutUs']['1']}
          </ItemCaption>
        </WhiteBox>
        <Image src={fourth} alt="AboutUs" />
      </Container>
      <Container>
        <Image src={secondBlock} alt="AboutUs" />
        <WhiteBox>
          <Title>{datas['Services']['2']}</Title>
          <ItemCaption>
          {datas['AboutUs']['2']}
          </ItemCaption>
        </WhiteBox>
      </Container>
      <Container>
        <WhiteBox>
          <Title>{datas['Services']['3']}</Title>
          <ItemCaption>
          {datas['AboutUs']['3']}
          </ItemCaption>
        </WhiteBox>
        <Image src={firstBlock} alt="AboutUs" />
      </Container>
      <Container>
        <Image src={thirdBlock} alt="AboutUs" />
        <WhiteBox>
          <Title>{datas['Services']['4']}</Title>
          <ItemCaption>
          {datas['AboutUs']['4']}
          </ItemCaption>
        </WhiteBox>
      </Container>
      <Container>
        <WhiteBox>
          <Title>{datas['Services']['5']}</Title>
          <ItemCaption>
          {datas['AboutUs']['5']}
          </ItemCaption>
        </WhiteBox>
        <Image src={fifth} alt="AboutUs" />
      </Container>
    </Main>
  )
}

function AboutUsMobile() {
  const {datas} = useContext(MenuContext)
  return (
    <Main id="aboutus">
      <Caption>{datas['AboutUs']['title']}</Caption>
      <Container>
        <ImageContainer>
          <Image src={fourth} alt="AboutUs" />
          <TitleContainer>
            <Title>{datas['Services']['1']}</Title>
            <ItemCaption>
              {datas['AboutUs']['1']}
            </ItemCaption>
          </TitleContainer>
        </ImageContainer>
      </Container>
      <Container>
        <ImageContainer>
          <Image src={secondBlock} alt="AboutUs" />
          <TitleContainer>
            <Title>{datas['Services']['2']}</Title>
            <ItemCaption>
              {datas['AboutUs']['2']}
            </ItemCaption>
          </TitleContainer>
        </ImageContainer>
      </Container>
      <Container>
        <ImageContainer>
          <Image src={firstBlock} alt="AboutUs" />
          <TitleContainer>
            <Title>{datas['Services']['3']}</Title>
            <ItemCaption>
              { datas['AboutUs']['3']}
            </ItemCaption>
          </TitleContainer>
        </ImageContainer>
      </Container>
      <Container>
        <ImageContainer>
          <Image src={thirdBlock} alt="AboutUs" />
          <TitleContainer>
            <Title>{datas['Services']['4']}</Title>
            <ItemCaption>
            {datas['AboutUs']['4']}
            </ItemCaption>
          </TitleContainer>
        </ImageContainer>
      </Container>
      <Container>
        <ImageContainer>
          <Image src={fifth} alt="AboutUs" />
          <TitleContainer>
            <Title>{datas['Services']['5']}</Title>
            <ItemCaption>
            {datas['AboutUs']['5']}
            </ItemCaption>
          </TitleContainer>
        </ImageContainer>
      </Container>
    </Main>
  )
}

export default AboutUs
