import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { NavHashLink } from 'react-router-hash-link'
import styled, { css } from 'styled-components'
import { MenuContext } from '../context/navState'
import { useMediaQuery } from 'react-responsive'
import Language, { LanguageMobile } from './Language'

const Menu = styled.nav`
  position: relative;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 293;
  display: inline;
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
  padding: 0px;
  align-items: stretch;
  background-color: #262e36;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  vertical-align: middle;

  ${(props) =>
    props.open &&
    css`
      transform: translateX(0);
    `}

  @media (max-width: 768px) {
    position:relative;
    display:grid;
    grid-template-columns:1fr;
    align-items: left;
    width: 10px;
  }
`

export const MenuLink = styled.a`
  position: relative;
  text-align: left;
  max-width: 100%;
  width:100%;
  padding-left: 10px;
  background-position: 88% 50%;
  background-size: 24px;
  transition: background-position 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-decoration: none;
  color: #08a332;
  font-size: 24px;
  font-family: 'FuturaPT-Bold', sans-serif;
  letter-spacing: 0.2px;

  :hover {
    background-position: 90% 50%;
    color: #2fba93;
  }
  @media (max-width: 768px) {
    display:flex;
    font-size: 18px;
    
  }
`

export function SideMenu({ children }) {
  const { isMenuOpen } = useContext(MenuContext)
  const {datas} = useContext(MenuContext)
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })

  return (
    <Menu style={!isMenuOpen ? { display: 'none' } : {}} open={isMenuOpen}>
      <NavHashLink smooth to="/#main">
        <MenuLink>
        {!isMobile ? <Language/> :  <LanguageMobile />}
      </MenuLink>
      </NavHashLink>
      
      <NavHashLink smooth to="/#main">
        <MenuLink>{datas['HomePage']}</MenuLink>
      </NavHashLink>
      <NavHashLink smooth to="/#services">
        <MenuLink>{datas['Services']['title']}</MenuLink>
      </NavHashLink>
      <NavHashLink smooth to="/#about-us">
        <MenuLink>{datas['AboutUs']['title']}</MenuLink>
      </NavHashLink>
      <NavHashLink smooth to="/#contacts">
        <MenuLink>{datas['Contacts']['title']}</MenuLink>
      </NavHashLink>
    </Menu>
  )
}

SideMenu.propTypes = {
  children: PropTypes.node,
}

// SideMenu.defaultProps = {
//   children: (
//     <>
//       <Language/>
//       <NavHashLink smooth to="/#main">
//         <MenuLink>{data[{lang}=useContext(MenuContext)]['HomePage']}</MenuLink>
//       </NavHashLink>
//       <NavHashLink smooth to="/#services">
//         <MenuLink>{data[language]['Services']}</MenuLink>
//       </NavHashLink>
//       <NavHashLink smooth to="/#cases">
//         <MenuLink>{data[language]['OurWorks']}</MenuLink>
//       </NavHashLink>
//       <NavHashLink smooth to="/#about-us">
//         <MenuLink>{data[language]['AboutUs']}</MenuLink>
//       </NavHashLink>
//       <NavHashLink smooth to="/#contacts">
//         <MenuLink>{data[language]['Contacts']}</MenuLink>
//       </NavHashLink>
//     </>
//   ),
// }
