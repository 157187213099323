import styled from 'styled-components'
import { FaFacebookSquare, FaInstagram, FaLinkedin } from 'react-icons/fa'

const Main = styled.footer`
  background: 0 0;
  border-top: 4px solid #08a332;
  color: #7e8889;
  font-size: 12px;
  position: relative;
  width: 100%;
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  position: relative;
`
const Title = styled.div`
  position: relative;
`
const SocialLinks = styled.div`
  display: flex;
  margin-left: 15px;
  padding-left: 24px;
`
const Link = styled.a`
  color: #7e8889;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  margin-right: 0.8rem;
`

function Footer() {
  return (
    <Main>
      <Container>
        <Title>© 2024 Paýhasly Ýoldaş</Title>
        {/* <SocialLinks>
          <Link href="https://www.instagram.com/payyol.apc/">
            <h3>
              <FaInstagram />
            </h3>
          </Link>
          <Link href="https://www.linkedin.com/company/payhasly-yoldash/mycompany/">
            <h3>
              <FaLinkedin />
            </h3>
          </Link>
        </SocialLinks> */}
      </Container>
    </Main>
  )
}

export default Footer
