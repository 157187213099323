import React, { useRef, useContext } from 'react'
import styled from 'styled-components'
import { MenuContext } from '../context/navState'
import HamburgerButton from './HamburgerButton'
import { NavHashLink } from 'react-router-hash-link'
import Language from './Language'
import { SideMenu } from './SideMenu'
import { useMediaQuery } from 'react-responsive'

const Navbar = styled.div`
  position: fixed;
  display: flex;
  overflow: hidden;
  left: 0;
  right: 0;
  max-width: 100%;
  margin: 0px;
  align-items: center;
  vertical-align: middle;
  background: #262e36 none repeat scroll 0% 0%;
  min-width: 0px;
  min-height: 0px;
  z-index: 9999;
  flex-direction: row-reverse;
  padding: 0 40px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px;

  @media (max-width: 768px) {
    display: block;
    flex-direction: column;
    padding: 0;
    }
  }
`

const Logo = styled.h1`
  color: #08a332;
  font-family: 'FuturaPT-Bold', cursive;
  font-size: 35px;
  letter-spacing: 4px;
  margin-right: 20px;
  margin-left: auto;
  margin-top: 15px;
  line-height: 25px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 22px;
    margin-right: 0;
    margin-top: 12.4px;
    letter-spacing: 1.5px;
  }
`

function MainMenu() {
  const node = useRef()
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext)
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })
  return (
    <header ref={node}>
      <div>
      <Navbar>
      <HamburgerButton />
        <Logo>Paýhasly Ýoldaş</Logo>
     
        <SideMenu />
        <NavHashLink smooth to="/#main">
           {!isMenuOpen && !isMobile && <Language/>}
        </NavHashLink>
        
      </Navbar>
    
    </div>
    </header>
  )
}

export default MainMenu
