import React from 'react'
import styled from 'styled-components'
import MainMenu from './components/MainMenu'
import AboutPage from './components/AboutPage'
import Services from './components/Services'
import Cases from './components/Cases'
import AboutUs from './components/AboutUs'
import Contacts from './components/Contacts'
import Footer from './components/Footer'
import NavState from './context/navState'

const Grid = styled.div`
  // auto-height: 100%;
  // auto-width: 100%;
`

const Box = styled.div`
  justify-content: center;
  align-items: center;
`

function App() {
  return (
    <NavState>
      <Grid>
        <MainMenu />
      <Box>
        <AboutPage />
        <Services />
        <Cases />
        <AboutUs />
        <Contacts />
        <Footer />
      </Box>
    </Grid>
    </NavState>
    
  )
}

export default App
