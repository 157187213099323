import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import data from '../data'
export const MenuContext = createContext({
  isMenuOpen: true,
  toggleMenu: () => {},
  lang: 'turkmen',
  LangHandler: ()=>{},
  setLang: () =>{},
  datas: {}
})

function NavState({ children }) {
  const [isMenuOpen, toggleMenu] = useState(false)

  function toggleMenuMode() {
    toggleMenu(!isMenuOpen)
  }
  const [lang, setLang ]= useState('russian')
  


  const datas = data[lang]
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MenuContext.Provider value={{ isMenuOpen, toggleMenuMode,lang,setLang,datas }}>
      {children}
    </MenuContext.Provider>
  )
}

NavState.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavState
