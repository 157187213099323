import React, { useContext } from 'react'
import styled from 'styled-components'
import { MenuContext } from '../context/navState'

const MenuButton = styled.button`
  display: block;
  transform-origin: 16px 11px;
  float: left;
  margin-right: 29px;
  outline: 0;
  border: 0;
  padding: 12px;
  background: none;

  @media (max-width: 768px) {
    margin-right: 0;
  }

  span {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    @media (max-width: 768px) {
      width: 24px;
      height: 4px;
      margin: 4px;
    }
  }

  :focus {
    outline: 0;
  }

  :hover {
    span:nth-of-type(1) {
      width: 33px;
      @media (max-width: 768px) {
        width: 30px;
      }
    }

    span:nth-of-type(2) {
      width: 40px;
      @media (max-width: 768px) {
        width: 30px;
      }
    }

    span:nth-of-type(3) {
      width: 30px;
      @media (max-width: 768px) {
        width: 30px;
      }
    }
  }

  &.active {
    span:nth-of-type(1) {
      transform: rotate(45deg) translate(10px, 10px);
      width: 40px;
      @media (max-width: 768px) {
        width: 30px;
      }
    }

    span:nth-of-type(2) {
      opacity: 0;
      pointer-events: none;
      @media (max-width: 768px) {
        width: 30px;
      }
    }

    span:nth-of-type(3) {
      transform: rotate(-45deg) translate(7px, -7px);
      width: 40px;
      @media (max-width: 768px) {
        width: 30px;
      }
    }
  }
`

const Bar = styled.span`
  display: block;
  width: 40px;
  height: 5px;
  margin-bottom: 7px;
  background-color: #08a332;
`

function HamburgerButton() {
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext)

  const clickHandler = () => {
    toggleMenuMode()
  }

  return (
    <MenuButton
      className={isMenuOpen ? 'active' : ''}
      aria-label="Открыть главное меню"
      onClick={clickHandler}
    >
      <Bar />
      <Bar />
      <Bar />
    </MenuButton>
  )
}

export default HamburgerButton
