import styled from 'styled-components'
import { IoIosArrowDropright } from 'react-icons/io'
import React, { useState } from 'react'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 0;
`

const Item = styled.div`
  width: 100%;
  height: 16rem;
  padding: 30px;
  margin: 0 0.6rem;
  font-size: 30px;
  color: #fff;

  @media (max-width: 1480px) {
    padding: 20px;
    width: 100%;
    font-size: 30px;
    height: 220px;
    margin: 0 0.4rem;

    @media (max-width: 768px) {
      padding: 10px 10px 10px 17px;
      height: 40px;
      margin: 0.1rem 0;
    }

    @media (max-width: 654px) {
      padding: 8px;
    }
  }
`

function Box({ color, children }) {
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    setIsActive(!isActive)
  }

  return (
    <Wrapper>
      <Item isActive={isActive} style={{ backgroundColor: color }}>
        {children}
      </Item>
    </Wrapper>
  )
}

export default Box
