import React from "react";
import { useContext } from "react";
import { MenuContext } from "../context/navState";

const Language = () => {
    const {setLang} = useContext(MenuContext)
    return (
            <div style={{display:'inline'}}>
                <span style={{marginRight:'5px',fontSize: '24px',cursor:'pointer'}} onClick={()=>setLang('turkmen')}>🇹🇲</span>
                <span style={{marginRight:'5px', fontSize: '24px',cursor:'pointer'}} onClick={()=>setLang('russian')}>🇷🇺</span>
                <span style={{marginRight:'5px', fontSize: '24px',cursor:'pointer'}} onClick={()=>setLang('english')}>🇬🇧</span>
            </div>
     
        
        
    )
}

export const LanguageMobile = () => {
    const {setLang} = useContext(MenuContext)
    return (
            <div style={{display:'flex'}}>
                <span style={{marginRight:'5px',fontSize: '24px',cursor:'pointer'}} onClick={()=>setLang('turkmen')}>🇹🇲</span>
                <span style={{marginRight:'5px', fontSize: '24px',cursor:'pointer'}} onClick={()=>setLang('russian')}>🇷🇺</span>
                <span style={{marginRight:'5px', fontSize: '24px',cursor:'pointer'}} onClick={()=>setLang('english')}>🇬🇧</span>
            </div>
     
        
        
    )
}

export default Language;