import { useContext } from 'react'
import styled from 'styled-components'
import { MenuContext } from '../context/navState'
import Box from './Box'
const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 9rem;
  padding-right: 9rem;

  @media (max-width: 1430px) {
    padding-left: 8rem;
    padding-right: 8rem;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0 25px;
    }
  }
`

const Caption = styled.h2`
  font-family: 'FuturaPT-Bold', cursive;
  margin: 5rem 0 2rem 0;
  font-size: 65px;
  line-height: 65px;
  text-transform: uppercase;
  color: #08a332;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`

const ServicesContainer = styled.div`
  box-sizing: border-box;
`
const ContainerRow = styled.div`
  display: flex;
  margin: 20px 0;
  justify-content: center;
  align-items: center;

  @media (max-width: 1480px) {
    margin: 0.7rem;
  }

  @media (max-width: 768px) {
    display: block;
    justify-content: space-between;
    margin: 0;
  }
`
const Title = styled.h1`
  font-family: 'FuturaPT-Bold', cursive;
  font-size: 35px;
  color: #fff;
  margin: 0 auto;

  @media (max-width: 1470px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 20px;
  }

  @media (max-width: 668px) {
    font-size: 18px;
  }

  @media (max-width: 654px) {
    font-size: 16px;
  }
`

function Services() {
  const {datas}=useContext(MenuContext)

  return (
    <Main id="services">
      <Caption>{datas['Services']['title']}</Caption>
      <ServicesContainer>
        <ContainerRow>
          <Box color="#08a332">
            <Title>{datas['Services']['1']}</Title>
          </Box>
          <Box color="#c73e1c">
            <Title>{datas['Services']['2']}</Title>
          </Box>
          <Box color="#248a88">
            <Title>{datas['Services']['3']}</Title>
          </Box>
        </ContainerRow>
        <ContainerRow>
          <Box color="#80257e">
            <Title>{datas['Services']['4']}</Title>
          </Box>
          <Box color="#0c64c9">
            <Title>{datas['Services']['5']}</Title>
          </Box>
        </ContainerRow>
      </ServicesContainer>
    </Main>
  )
}
export default Services
