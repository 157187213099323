import { useContext } from 'react'
import styled from 'styled-components'
import bc from '../assets/bc.jpg'
import LogoRU from '../assets/logoRU.png'
import LogoTM from '../assets/logoTM.png'
import LogoEN from '../assets/logoEN.png'
import MyFile from '../assets/MyFile.pdf'
import { MenuContext } from '../context/navState'

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: inherit;
  margin-bottom: 5rem;
  background-image: url(${bc});
  background-size: cover;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 25px;
    margin-bottom: 2rem;
  }
`
const InfoContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 13rem;
  padding-left: 10rem;

  @media (max-width: 1500px) {
    padding: 0;
  }

  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    padding: 0;
  }
`

const HeadQuote = styled.h1`
  width: 100%;
  font-family: 'FuturaPT-Bold', sans-serif;
  font-size: 60px;
  line-height: 64px;
  text-align: left;
  color: white;

  @media (max-width: 1500px) {
    font-size: 55px;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
  }
`
const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Button = styled.button`
  margin: 2rem 0 0 1rem;
  border: 2px solid #c8d2d3;
  transition: all 0.15s ease-out;
  padding: 15px 20px;
  font-family: 'FuturaPT-Bold', sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
  color: #c8d2d3;
  background-color: #101820;

  :hover {
    background-color: #2fba93;
    border: 2px solid #2fba93;
  }

  @media (max-width: 768px) {
    margin: 1rem 0 0 0;
    font-size: 17px;
    padding: 10px 20px;
  }
`
const Container = styled.div`
  display: flex;
  flex-basis: 650px;
  flex-shrink: 1;
  margin: 15rem 0 0 10rem;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
    margin: 0;
    flex-basis: auto;
  }
`

const Logo = styled.img`
  width: 100%;

  @media (max-width: 1500px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 55%;
    height: auto;
  }
`

function AboutPage() {
  const {datas,lang} = useContext(MenuContext)
  return (
    <Main id="main">
      <Container>
        {lang === 'russian' && <Logo src={LogoRU}  alt="Payhasly Yoldash" />}
        {lang === 'turkmen' && <Logo src={LogoTM}  alt="Payhasly Yoldash" />}
        {lang === 'english' && <Logo src={LogoEN}  alt="Payhasly Yoldash" />}
      </Container>
      <InfoContainer>
        <HeadQuote>
          {datas['Main1']}<br/>{datas['Main2']}
          {/* Делаем так, <br /> чтобы вас запомнили */}
        </HeadQuote>
        {/* <ButtonContainer>
          <a href={MyFile} download="MyFile" target="_blank">
            <Button>Посмотреть</Button>
          </a>
        </ButtonContainer> */}
      </InfoContainer>
    </Main>
  )
}

export default AboutPage
